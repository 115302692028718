import React from 'react';
import '../WasSchBer.css';

const HeaderComponent = ({ timer }) => (
  <header className="header">
    <img src="https://wasschber.de/wp-content/uploads/2023/12/WASSCHBER_weiss_bunt.png" alt="Logo" className="header-logo" />
    <h1 className="header-title">WasSchBer Service Monitoring</h1>
    <p className="header-timer">Nächste Aktualisierung in {timer} Sekunden</p>
  </header>
);

export default HeaderComponent;
