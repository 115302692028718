import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css'; // Optional, falls du eine CSS-Datei für Styles hinzufügst
import './WasSchBer.css'; // Eigene CSS-Datei
import App from './App';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react"; // Importiere den MSAL-Provider

const msalConfig = {
  auth: {
    clientId: "ac6ccb81-c6f0-4429-9bd6-b981ee8cadec",
    authority: "https://login.microsoftonline.com/d5a70924-375c-4c41-af30-79c4e676a41e",
    redirectUri: "https://www.wasschber.cloud/", // Production-Redirect-URI
  },
};

// Erstelle die MSAL-Instanz
export const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);

