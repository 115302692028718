import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const ServerMonitoring = ({ serverData }) => {
    return (
        <div className="container mt-4">
            <h2>Server Monitoring</h2>

            <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                    <tr>
                        <th>Server</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {serverData.map((server, index) => (
                        <tr key={index} className={server.status === "ok" ? "table-success" : "table-danger"}>
                            <td>{server.server}</td>
                            <td>{server.status === "ok" ? "OK" : "Error"}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ServerMonitoring;
