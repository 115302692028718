import React, { useState } from 'react';
import '../WasSchBer.css';


const Footer = () => {
  return (
    <footer className="footer-container">
      &copy; 2024 WasSchBer GmbH & Co. KG{' '}
      <a href="https://wasschber.de/impressum-datenschutz/" target="_blank" rel="noopener noreferrer" className="footer-link">
        Impressum
      </a>{' '}
      <a href="https://wasschber.de/impressum-datenschutz/" target="_blank" rel="noopener noreferrer" className="footer-link">
        Datenschutz
      </a>{' '}
      <a href="https://wasschber.de/impressum-datenschutz/" target="_blank" rel="noopener noreferrer" className="footer-link">
        AGB
      </a>
    </footer>
  );
};

export default Footer;
