// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-label {
    padding: 8px;
    margin: 5px;
    border-radius: 4px;
    color: white;
    font-weight: bold;
}
.status-label.green {
    background-color: green;
}
.status-label.red {
    background-color: red;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,qBAAqB;AACzB","sourcesContent":[".status-label {\n    padding: 8px;\n    margin: 5px;\n    border-radius: 4px;\n    color: white;\n    font-weight: bold;\n}\n.status-label.green {\n    background-color: green;\n}\n.status-label.red {\n    background-color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
