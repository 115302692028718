import axios from 'axios';

const API_URL = 'https://www.wasschber.cloud/heartbeat_info';
const API_SECRET = process.env.REACT_APP_API_SECRET;
const API_TOKEN = process.env.REACT_APP_API_TOKEN;

export const fetchMonitoringData = async () => {
    try {
        const response = await fetch(API_URL, {
            method: 'GET',
            headers: {
                'secret-key': API_SECRET,
                'api-token': API_TOKEN
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP-Fehler! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error("Fehler beim Abrufen der Monitoring-Daten", error);
        throw error;
    }
};
