import React, { useState, useEffect } from "react";
import ServerMonitoring from "./components/ServerMonitoring";
import ServiceMonitoring from "./components/ServiceMonitoring";
import HeaderComponent from "./components/HeaderComponent";
import Footer from "./components/Footer";
import { fetchMonitoringData } from "./api";
import "bootstrap/dist/css/bootstrap.min.css";
import { msalInstance } from "./index";

const App = () => {
    const [serverData, setServerData] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [timer, setTimer] = useState(60);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loginChecked, setLoginChecked] = useState(false); // Flag, um doppeltes Login zu verhindern

    useEffect(() => {
        const handleRedirect = async () => {
            try {
                await msalInstance.initialize();
                // Prüfe, ob die MSAL-Instanz initialisiert ist
                if (!msalInstance) {
                    throw new Error("MSAL-Instanz ist nicht initialisiert.");
                }

                // Verarbeite Redirect-Ergebnisse
                const response = await msalInstance.handleRedirectPromise();
                if (response) {
                    console.log("Login erfolgreich:", response);
                    setIsAuthenticated(true);
                } else {
                    const accounts = msalInstance.getAllAccounts();
                    if (accounts.length > 0) {
                        setIsAuthenticated(true);
                    }
                }
            } catch (error) {
                console.error("Fehler beim Verarbeiten des Redirects:", error);
                setErrorMessage("Fehler beim Verarbeiten des Logins.");
            }
        };

        handleRedirect();
    }, []);

    useEffect(() => {
        const checkLogin = async () => {
            try {
                await msalInstance.initialize();
                const accounts = msalInstance.getAllAccounts();
                if (accounts.length > 0) {
                    setIsAuthenticated(true);
                } else {
                    console.log("Starte Redirect-Login...");
                    await msalInstance.loginRedirect({
                        scopes: ["User.Read"],
                    });
                }
            } catch (error) {
                console.error("Fehler beim Redirect-Login:", error);
                setErrorMessage("Login fehlgeschlagen. Bitte versuchen Sie es erneut.");
            }
        };

        checkLogin();
    }, [isAuthenticated]);

    useEffect(() => {
        if (!isAuthenticated) return;

        const fetchData = async () => {
            try {
                const data = await fetchMonitoringData();
                setServerData(data.SERVER_HEARTBEAT || []);
                setServiceData(data.SERVICE_HEARTBEAT || []);
            } catch (error) {
                console.error("Fehler beim Abrufen der Daten:", error);
            }
        };

        fetchData();

        const intervalId = setInterval(() => {
            fetchData();
            setTimer(60);
        }, 60000);

        const countdownId = setInterval(() => {
            setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
        }, 1000);

        return () => {
            clearInterval(intervalId);
            clearInterval(countdownId);
        };
    }, [isAuthenticated]);

    if (!isAuthenticated) {
        return (
            <div>
                {errorMessage ? (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                ) : (
                    "Lade Login..."
                )}
            </div>
        );
    }

    return (
        <div className="App" style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <HeaderComponent timer={timer} />
            <div className="container mt-4" style={{ flex: 1 }}>
                <div className="row">
                    <div className="col-md-6">
                        <ServerMonitoring serverData={serverData} />
                    </div>
                    <div className="col-md-6">
                        <ServiceMonitoring serviceData={serviceData} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default App;
