// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Header Styles */
.header {
    background-color: #022f40;
    color: #fff;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .header-logo {
    height: 40px;
    margin-right: 15px;
  }
  
  .header-title {
    font-size: 1.5rem;
    margin: 0;
    flex: 1 1;
  }
  
  .header-timer {
    margin: 0;
    font-size: 1rem;
  }
  
  /* Footer Styles */
  .footer-container {
    background-color: #022f40;
    color: white;
    text-align: center;
    padding: 10px 0;
    width: 100%;
    margin-top: auto;
    position: sticky;
    bottom: 0;
  }
  
  .footer-link {
    background: none;
    border: none;
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
    font-size: inherit;
  }
  
  /* Impressum Modal Styles */
  .impressum-text h2 {
    text-align: center;
  }
  
  .impressum-text p {
    text-align: justify;
    line-height: 1.5;
  }
  
  .impressum-text ul {
    list-style-type: none;
    padding: 0;
  }
  
  .impressum-text ul li {
    margin-bottom: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/WasSchBer.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,MAAM;IACN,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,SAAS;IACT,SAAO;EACT;;EAEA;IACE,SAAS;IACT,eAAe;EACjB;;EAEA,kBAAkB;EAClB;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,SAAS;EACX;;EAEA;IACE,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,0BAA0B;IAC1B,eAAe;IACf,kBAAkB;EACpB;;EAEA,2BAA2B;EAC3B;IACE,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,qBAAqB;IACrB,UAAU;EACZ;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":["/* Header Styles */\n.header {\n    background-color: #022f40;\n    color: #fff;\n    padding: 10px 20px;\n    display: flex;\n    align-items: center;\n    position: sticky;\n    top: 0;\n    z-index: 1000;\n  }\n  \n  .header-logo {\n    height: 40px;\n    margin-right: 15px;\n  }\n  \n  .header-title {\n    font-size: 1.5rem;\n    margin: 0;\n    flex: 1;\n  }\n  \n  .header-timer {\n    margin: 0;\n    font-size: 1rem;\n  }\n  \n  /* Footer Styles */\n  .footer-container {\n    background-color: #022f40;\n    color: white;\n    text-align: center;\n    padding: 10px 0;\n    width: 100%;\n    margin-top: auto;\n    position: sticky;\n    bottom: 0;\n  }\n  \n  .footer-link {\n    background: none;\n    border: none;\n    color: #fff;\n    text-decoration: underline;\n    cursor: pointer;\n    font-size: inherit;\n  }\n  \n  /* Impressum Modal Styles */\n  .impressum-text h2 {\n    text-align: center;\n  }\n  \n  .impressum-text p {\n    text-align: justify;\n    line-height: 1.5;\n  }\n  \n  .impressum-text ul {\n    list-style-type: none;\n    padding: 0;\n  }\n  \n  .impressum-text ul li {\n    margin-bottom: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
